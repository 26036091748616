import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <>

<div className="container-fluid bg-dark px-5">
          <div className="row gx-4 d-none d-lg-flex">
            <div className="col-lg-6 text-start">
              <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                <div className="btn-sm-square rounded-circle bg-primary me-2">
                  <small className="fa fa-map-marker-alt text-white" />
                </div>
                <small>{companyaddress}</small>
              </div>
              
            </div>
            <div className="col-lg-6 text-end">
              <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                <div className="btn-sm-square rounded-circle bg-primary me-2">
                  <small className="fa fa-phone-alt text-white" />
                </div>
                <small>{companynumber}</small>
              </div>
              <div className="h-100 d-inline-flex align-items-center py-3">
              <div className="btn-sm-square rounded-circle bg-primary me-2">
                  <small className="fa fa-envelope-open text-white" />
                </div>
                <small>{companyemail}</small>
              </div>
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5">
          <Link to="/Home" className="navbar-brand d-flex align-items-center">
           <img src="img/logo.png" style={{height:"80px"}}></img>
          </Link>
          <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-4 py-lg-0">
              <Link to="/Home" className="nav-item nav-link active">Home</Link>
              <Link to="/About" className="nav-item nav-link">Company Profile</Link>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Freight Forwarding</Link>
                <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                  <Link to="/Air" className="dropdown-item">Air Freight</Link>
                  <Link to="/Ocean" className="dropdown-item">Ocean Freight</Link>
                  <Link to="/Ground" className="dropdown-item">Ground Freight</Link>
                  <Link to="/Rail" className="dropdown-item">Rail Freight</Link>
                  <Link to="/Projects" className="dropdown-item">Projects Logistics</Link>
                  <Link to="/Customs" className="dropdown-item"> Customs Brokerage</Link>

                </div>
              </div>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Trading Services</Link>
                <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                  <Link to="/Trading" className="dropdown-item">Fashion & Apparel</Link>
                  <Link to="/Trading" className="dropdown-item">Food & Beverage</Link>
                  <Link to="/Trading" className="dropdown-item">Oil, Gas & Energy</Link>
                  <Link to="/Trading" className="dropdown-item">FMCG</Link>
                  <Link to="/Trading" className="dropdown-item">Electronics </Link>
                  <Link to="/Trading" className="dropdown-item">Agriculture & Agribusiness</Link>
                  
                </div>
              </div>
              
              <Link to="/Contact" className="nav-item nav-link">Contact</Link>
            </div>
            <div className="h-100 d-lg-inline-flex align-items-center d-none">
            <Link to="/Getquote" className="btn btn-primary rounded-pill py-3 px-5">Get A Quote</Link>
            </div>
          </div>
        </nav>
    </>
  );
};

export default Header;
