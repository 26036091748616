import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
          Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-primary mb-3 mx-auto"
              style={{ width: "60px", height: "2px" }}
            />
            <h1 className="display-5 mb-5">Our Freight Forwarding Services</h1>
          </div>

          <div className="row g-4 portfolio-container">
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/air.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Air Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Air Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Air"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/ocean.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Ocean Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Ocean Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Ocean"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/road.webp"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Ground Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Ground Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Ground"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/rail.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Rail Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Rail Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Rail"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/project.png"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Projects Logistics</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Projects Logistics</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Projects"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/customs.png"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Customs Brokerage</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Customs Brokerage</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Customs"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-primary mb-3 mx-auto"
              style={{ width: "60px", height: "2px" }}
            />
            <h1 className="display-5 mb-5">Trading Solutions We Provide..</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/fashion.webp"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Fashion & Apparel</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/food.webp"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Food & Beverage</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/oil.jpg"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Oil, Gas & Energy</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/fmcg.jpg"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">FMCG</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/electronics.jpg"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Electronics</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/agriculture.webp"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Fashion & Apparel</h5>
                  <span className="text-primary">
                    Agriculture & Agribusiness
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
