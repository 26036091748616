import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
          Customs Brokerage
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Customs Brokerage
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center">
            <div
              
            />
            <h1 className="display-5 mb-5">Customs Brokerage</h1>
          </div>
          <div className="row g-0 service-row">
            <div className="col-md-12 col-lg-12 wow fadeIn p-4" data-wow-delay="0.1s">
               <div className="text-center p-6">
                  <img
                    className="img-fluid"
                    src="img/customs.png" style={{padding:"20px 80px"}}
                  /></div>
                <p className="mb-4 ">
                In today's dynamic global marketplace, the ever-evolving demands pertaining to production and delivery timelines have magnified the role of Customs Brokers, necessitating their adept provision of expert customs clearance advisory and brokerage services. Irrespective of the swiftness with which a consignment traverses its path to destination points, the intricate web of customs procedures and documentation requisites can cast shadows of uncertainty, leading to unforeseen delays that incur substantial storage charges for consignees.
<br />
At {companyname}, our licensed customs brokers stand as virtuosos in comprehending the intricate customs procedures that unfurl within each unique operational domain. With an acumen honed through years of experience, our customs brokerage team assumes the mantle of responsibility, orchestrating the meticulous preparation of accurate documentation well in advance of import and export deadlines. This proactive approach stands as a bulwark against the tide of expensive and time-consuming delays, safeguarding your cargo's journey from uncertainty.
<br />
To amplify the potency of this facet of our operations, {companyname} has erected a robust network of bonded warehousing facilities across key regions, including China, the CIS, and the Middle East. These strategically positioned facilities serve as havens for diverse cargo types, accessible via road, rail, or air routes. This strategic positioning ensures that shipments encounter swift customs clearance upon their point of arrival, seamlessly transitioning from international transit to localized distribution.
<br />
In the realm of customs brokerage, {companyname} isn't merely a service provider; we are architects of expediency. With each customs hurdle surmounted, each documentation meticulously prepared, and each delay preempted, we stand as your unwavering partners in steering your cargo's passage towards success. As you entrust us with your customs clearance, you also entrust us with your cargo's timeline, and we stand resolute in our dedication to ensuring that your cargo sails through customs with precision, speed, and an unwavering commitment to your objectives.
                  
                  
                </p>
            </div>
           
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
