import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Trading Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Trading Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center">
            <div
              className="bg-primary mb-3 mx-auto"
              style={{ width: "60px", height: "2px" }}
            />
            <h1 className="display-5 mb-5">Our Trading Services</h1>
          </div>
          <div className="row g-0 service-row">
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="service-item border h-100 p-5">
                <div
                  className="btn-square bg-light  mb-4"
                  style={{ width: "340px", height: "340px" }}
                >
                  <img
                    className="img-fluid"
                    src="img/fashion.webp"
                    style={{ width: "340px", height: "250px" }}
                  />
                </div>
                <h4 className="mb-3">Fashion & Apparel</h4>
                <p className="mb-4">
                  {companyname} stands as a beacon of expertise in providing
                  specialized project logistics services meticulously crafted to
                  align with the distinct demands of the fashion and apparel
                  industry. Our offerings are deftly curated to seamlessly
                  facilitate the secure and efficient transit of an array of
                  essentials within the global supply chain, spanning raw
                  materials, finished garments, accessories, and an array of
                  fashion-related products. Within this realm of specialized
                  services, we unfurl a tapestry of solutions, each tailored to
                  cater to the unique intricacies of the fashion and apparel
                  sector..
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="service-item border h-100 p-5">
                <div
                  className="btn-square bg-light  mb-4"
                  style={{ width: "340px", height: "340px" }}
                >
                  <img
                    className="img-fluid"
                    src="img/food.webp"
                    style={{ width: "340px", height: "250px" }}
                  />
                </div>
                <h4 className="mb-3">Food & Beverage</h4>
                <p className="mb-4">
                  {companyname} proudly presents a comprehensive array of
                  services meticulously curated to cater to the distinct
                  requisites of the food and beverage industry. Our offerings
                  serve as a steadfast support system, deftly facilitating the
                  seamless and efficient movement of both perishable and
                  non-perishable food items, beverages, and an array of products
                  across the intricate threads of the global supply chain.
                  <br />
                  Within this bespoke framework, our services encompass a wide
                  spectrum, each calibrated to harmonize with the unique demands
                  of the food and beverage sector
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="service-item border h-100 p-5">
                <div
                  className="btn-square bg-light  mb-4"
                  style={{ width: "340px", height: "340px" }}
                >
                  <img
                    className="img-fluid"
                    src="img/oil.jpg"
                    style={{ width: "340px", height: "250px" }}
                  />
                </div>
                <h4 className="mb-3">Oil, Gas & Energy</h4>
                <p className="mb-4">
                  {companyname} takes immense pride in presenting its
                  specialized project logistics services, meticulously crafted
                  to cater to the distinctive demands of the oil & gas and
                  energy industries. Our offerings serve as a beacon of support,
                  fostering the secure and seamless movement of extensive
                  equipment, machinery, and materials critical to the success of
                  energy-related projects. These encompass a diverse spectrum,
                  ranging from oil & gas exploration and drilling endeavors to
                  the intricate tapestry of refineries and power generation
                  facilities.
                  <br />
                  Within this bespoke framework, our services unfold as a robust
                  support system, addressing the complex requisites of these
                  industries.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="service-item border h-100 p-5">
                <div
                  className="btn-square bg-light  mb-4"
                  style={{ width: "340px", height: "340px" }}
                >
                  <img
                    className="img-fluid"
                    src="img/fmcg.jpg"
                    style={{ width: "340px", height: "250px" }}
                  />
                </div>
                <h4 className="mb-3">FMCG</h4>
                <p className="mb-4">
                  {companyname} proudly unveils an expansive spectrum of
                  services intricately designed to cater to the distinct demands
                  of the retail and consumer goods industry. Our offerings serve
                  as a steadfast support system, seamlessly facilitating the
                  efficient movement of goods, orchestrating their journey from
                  manufacturers to distribution centers, and ultimately finding
                  their way onto retail shelves and e-commerce platforms.
                  <br />
                  Within this bespoke framework, our services unfurl to
                  encompass a wide array of solutions, each calibrated to
                  resonate with the unique intricacies of the retail and
                  consumer goods sector
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="service-item border h-100 p-5">
                <div
                  className="btn-square bg-light  mb-4"
                  style={{ width: "340px", height: "340px" }}
                >
                  <img
                    className="img-fluid"
                    src="img/electronics.jpg"
                    style={{ width: "340px", height: "250px" }}
                  />
                </div>
                <h4 className="mb-3">Electronics</h4>
                <p className="mb-4">
                  {companyname} proudly presents a comprehensive array of
                  services intricately tailored to cater to the distinctive
                  requisites of the electronics and technology industry. Our
                  offerings stand as a stalwart pillar, deftly facilitating the
                  seamless and efficient movement of electronic devices,
                  components, and an array of technology products across the
                  intricate threads of the global supply chain.
                  <br />
                  Within this bespoke framework, our services span a wide
                  spectrum, each calibrated to harmonize with the unique demands
                  of the electronics and technology sector.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="service-item border h-100 p-5">
                <div
                  className="btn-square bg-light  mb-4"
                  style={{ width: "340px", height: "340px" }}
                >
                  <img
                    className="img-fluid"
                    src="img/agriculture.webp"
                    style={{ width: "340px", height: "250px" }}
                  />
                </div>
                <h4 className="mb-3">Agriculture & Agribusiness</h4>
                <p className="mb-4">
                  {companyname} takes pride in delivering a suite of specialized
                  project logistics services meticulously curated to cater to
                  the distinct requisites of the agriculture and agribusiness
                  sectors. Our offerings are ingeniously designed to expedite
                  the secure and streamlined transportation of agricultural
                  equipment, machinery, commodities, and a plethora of
                  agri-based products across the intricate threads of the global
                  supply chain.
                  <br />
                  <br />
                  Within this bespoke framework, we extend a range of pivotal
                  project logistics services, each calibrated to harmonize with
                  the unique demands of the agriculture and agribusiness
                  industries.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
