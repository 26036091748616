import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Air Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Air Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center">
            <div />
            <h1 className="display-5 mb-5">Air Freight</h1>
          </div>
          <div className="row g-0 service-row">
            <div
              className="col-md-12 col-lg-12 wow fadeIn p-4"
              data-wow-delay="0.1s"
            >
              <div className="text-center p-6">
                <img
                  className="img-fluid"
                  src="img/air.jpg"
                  style={{ padding: "20px 80px" }}
                />
              </div>
              <p className="mb-4 ">
                In the realm of time-sensitive supply chains, {companyname}{" "}
                emerges as your ultimate solution with our expansive worldwide
                air freight services. Whether it's the swift transportation of
                perishable goods, the secure handling of hazardous materials,
                the efficient movement of livestock, the execution of project
                cargo, or the delivery of any commodities demanding rapid
                transit, our offerings stand as the epitome of precision and
                speed.
                <br />
                Our global footprint spans the expanse of the globe, positioning
                us as the paramount choice for your time-critical cargoes. With
                an unwavering commitment to efficiency, we allocate a dedicated
                point of contact to shepherd you through every facet of your
                shipment – a seamless journey from door to door.
                <br />
                Irrespective of your cargo's origin, destination, dimensions, or
                nature, our comprehensive suite of air freight services stands
                poised to align with your budgetary considerations and
                requisites. We are not confined by boundaries; we are architects
                of possibilities, orchestrating solutions that resonate with the
                demands of your unique cargo.
                <br />
                At {companyname}, we understand that time is the essence of your
                supply chain. With each aircraft that takes to the skies, we
                carry not just cargo; we carry your aspirations, your
                commitments, and your success. As you entrust us with your
                cargo, you also entrust us with your timeline, and we stand
                unwavering in our commitment to ensuring that your cargo arrives
                with punctuality, precision, and an unwavering dedication to
                your objectives.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
