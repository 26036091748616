import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
          Projects Logistics
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Projects Logistics
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center">
            <div
              
            />
            <h1 className="display-5 mb-5">Projects Logistics</h1>
          </div>
          <div className="row g-0 service-row">
            <div className="col-md-12 col-lg-12 wow fadeIn p-4" data-wow-delay="0.1s">
               <div className="text-center p-6">
                  <img
                    className="img-fluid"
                    src="img/project.png" style={{padding:"20px 80px"}}
                  /></div>
                <p className="mb-4 ">
                In the realm of industrial project logistics management, the weight of experience is paramount, particularly when orchestrating the intricate ballet of oversized and heavy cargo transportation services. The management of complex transportation for expansive projects demands more than just expertise; it necessitates the presence of seasoned project logistics and heavy-lift specialists who ardently delve into an exhaustive exploration of optimization strategies and exhibit unwavering commitment to risk-mitigated end-to-end management of all operations.
<br />
Within the canvas of {companyname}, Industrial Project Logistics services unfurl to span an extensive spectrum, traversing the realms of Oil & Gas, Mining, Power, Petrochemicals, and beyond. Our portfolio is a testament to our readiness to serve a diverse array of sectors, echoing our capability to adapt and excel within multifaceted industrial terrains.
<br />
In our unwavering commitment to service excellence, we place paramount importance on synchronization and collaboration. Each project becomes a symphony of coordination, where we ensure harmonious interaction with all stakeholders involved. The complexity of every industrial project is acknowledged and respected, fueling the deployment of our global network of project logistics experts. This multifaceted team is poised to garner an intimate understanding of local dynamics, enabling the seamless management of materials and optimizing the cost-efficiency and reliability of supply chain management.
<br />
Bolstered by stringent risk-management protocols, we stand resolute in our dedication to bestowing unwavering reliability at an optimal cost, irrespective of the magnitude or scope of projects. At {companyname}, we aren't just project managers; we are architects of industrial triumphs. With every logistical puzzle solved, every risk averted, and every project seamlessly executed, we stand as your guardians of success, steering your industrial endeavors towards triumph, while minimizing costs and embracing the tenets of excellence.
                  
                  
                </p>
            </div>
           
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
