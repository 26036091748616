import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Rail Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Rail Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center">
            <div />
            <h1 className="display-5 mb-5">Rail Freight</h1>
          </div>
          <div className="row g-0 service-row">
            <div
              className="col-md-12 col-lg-12 wow fadeIn p-4"
              data-wow-delay="0.1s"
            >
              <div className="text-center p-6">
                <img
                  className="img-fluid"
                  src="img/rail.jpg"
                  style={{ padding: "20px 80px" }}
                />
              </div>
              <p className="mb-4 ">
                Rail freight emerges as a beacon of sustainable transportation
                solutions, underscored by its inherent ability to minimize fuel
                consumption compared to alternative shipping methods. This
                distinction becomes particularly pronounced when tasked with the
                transportation of voluminous or weighty cargo within land-locked
                regions. At {companyname}, our rail freight offerings stand as a
                testament to these sustainable advantages, offering a harmonious
                blend of efficiency, flexibility, and environmental stewardship.
                <br />
                Incorporating rail freight into your logistics equation unveils
                a realm of benefits that echo across various dimensions:
                <br />
                Fuel Efficiency: The heart of rail freight's sustainability lies
                in its lowered fuel consumption, an attribute that becomes
                remarkably pronounced when grappling with high-volume or heavy
                cargo. By opting for rail, you opt for a greener and more
                resource-efficient transportation avenue.
                <br />
                Land-Locked Advantage: For regions where landlocked geography
                poses transportation challenges, rail freight stands as a
                pragmatic solution. Its ability to traverse vast distances over
                land empowers even the most remote regions with seamless
                connectivity to global trade networks.
                <br />
                Flexible Dynamics: Our rail freight solutions are sculpted with
                flexibility at their core. Whether it's in the realm of pricing
                or the timeline of transportation, we ensure that your rail
                freight journey harmonizes with your specific requisites.
                <br />
                Environmental Stewardship: The conscientious choice of rail
                freight underscores your commitment to environmental
                responsibility. By opting for a mode of transportation with a
                lower carbon footprint, you actively contribute to the global
                endeavor of sustainability.
                <br />
                As you embark on a rail freight journey with {companyname}, you
                don't just opt for transportation; you embark on a path of
                conscientious progress. With each railway mile covered, your
                cargo resonates with an undertone of eco-friendliness,
                efficiency, and resilience. From origin to destination, we stand
                as your partners, bridging geographical expanse with the
                principles of sustainability and success.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
