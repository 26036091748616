import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Ocean Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Ocean Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center">
            <div />
            <h1 className="display-5 mb-5">Ocean Freight</h1>
          </div>
          <div className="row g-0 service-row">
            <div
              className="col-md-12 col-lg-12 wow fadeIn p-4"
              data-wow-delay="0.1s"
            >
              <div className="text-center p-6">
                <img
                  className="img-fluid"
                  src="img/ocean.jpg"
                  style={{ padding: "20px 80px" }}
                />
              </div>
              <p className="mb-4 ">
                ocean freight stands as an indispensable cornerstone of global
                commerce, serving as the robust backbone of the world economy.
                Leveraging our extensive global network and enduring
                partnerships with ocean carriers, we present a tapestry of ocean
                freight solutions that seamlessly weave flexibility,
                reliability, and security into a global scale. Our commitment
                extends beyond mere services; it encompasses a dedication to
                fostering seamless connections within the intricate realm of
                ocean freight.
                <br />
                Within our expansive portfolio, our customers unearth a treasure
                trove of advantages. From the vantage point of our expertise in
                international ocean freight forwarding, spanning a vast network
                that extends its reach across 65 countries, to the fortification
                of a state-of-the-art IT platform, we stand prepared to elevate
                your ocean freight experience to unparalleled heights.
                <br />
                As you embark on your ocean freight journey, our dedicated
                specialists stand as your navigational guides. From the realm of
                pricing intricacies to the meticulous selection of the optimal
                ocean carrier, and from the moment of pickup to the culmination
                of final delivery, we traverse this voyage with you. Our
                approach extends beyond logistics; it is a partnership, a
                collaboration wherein you are endowed with professional
                guidance, technical support, and unwavering supervision. With
                every maritime mile covered, our steadfast commitment remains
                fixed on ensuring the safe and secure delivery of your cargo,
                bridging the expanse between any origin and any destination
                across the globe.
                <br />
                At {companyname}, ocean freight isn't just a service; it's a
                voyage of excellence. With each vessel that sets sail, we carry
                your aspirations, your cargo, and your success. As guardians of
                your cargo's journey, we stand unwavering in our dedication to
                ensuring that every tide, every wave, and every port of call
                aligns with your objectives, delivering your cargo with the
                precision, security, and unwavering commitment that define the
                essence of {companyname}.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
