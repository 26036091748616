import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
          Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Contact Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Contact Start */}
      <div className="container-fluid bg-light overflow-hidden px-lg-0">
        <div className="container contact px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div
              className="col-lg-6 contact-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 ps-lg-0">
                <div className="section-title text-start">
                  <h1 className="display-5 mb-4">Contact Us</h1>
                </div>
                <form
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          className
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <textarea
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        name="submit"
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                <h1>{result}</h1>
              </div>
            </div>
            <div className="col-lg-6 pe-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                {" "}
         
                <iframe
                  className="position-absolute w-100 h-100"
                  style={{ objectFit: "cover" }}
                
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.888805760507!2d73.01924262520411!3d19.02462083216943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3beafe54bab%3A0xe5c983a0d946cbbf!2sHaware%20Mall!5e0!3m2!1sen!2sin!4v1691401384487!5m2!1sen!2sin"
                  frameBorder={0}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}

      <Footer />
    </>
  );
}
