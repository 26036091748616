import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Ground Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Ground Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center">
            <div />
            <h1 className="display-5 mb-5">Ground Freight</h1>
          </div>
          <div className="row g-0 service-row">
            <div
              className="col-md-12 col-lg-12 wow fadeIn p-4"
              data-wow-delay="0.1s"
            >
              <div className="text-center p-6">
                <img
                  className="img-fluid"
                  src="img/road.webp"
                  style={{ padding: "20px 80px" }}
                />
              </div>
              <p className="mb-4 ">
                Road transport stands as the epitome of versatility in the realm
                of freight carriage, offering an intricate tapestry of speed,
                cost-efficiency, and unparalleled flexibility for seamless
                door-to-door distribution services. At {companyname}, Ground
                freight serves as a conduit that harmonizes precision with
                progress, bridging geographical gaps with unwavering efficacy.
                <br />
                Our comprehensive Ground freight services encompass a tapestry
                of offerings, each meticulously designed to cater to diverse
                requisites:
                <br />
                LTL, FTL, and Break-Bulk: Whether it's Less Than Truckload (LTL)
                or Full Truckload (FTL) requirements, our Ground freight
                solutions span the spectrum, ensuring that your cargo is
                seamlessly transported according to its volume and nature.
                Break-bulk services further amplify the flexibility, enabling a
                tailored approach to your needs.
                <br />
                Heavy, Oversized, and Project Cargo: In the realm of heavy,
                oversized, and project cargo, our road transport prowess shines.
                We stand equipped to handle the intricacies of these unique
                cargoes, orchestrating their movement with precision.
                <br />
                Customs Bonded Transportation: Our licensed prowess extends to
                customs bonded transportation, offering a seamless passage that
                adheres to regulatory requisites, ensuring that your cargo
                journeys without impediments.
                <br />
                Dry, Bulk, and Liquid Transportation: The spectrum of dry, bulk,
                and liquid cargo finds a harmonious passage within our Ground
                freight services. Whether it's the transportation of goods in
                bulk or the efficient movement of liquids, our solutions are
                engineered to cater to your unique needs.
                <br />
                Dangerous Goods and Perishable Items: With the requisite
                expertise, we navigate the nuances of dangerous goods
                transportation, ensuring compliance with safety standards.
                Similarly, perishable goods find a dedicated path, preserving
                their freshness throughout the journey.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
