import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
          About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src="img/air-plane.png"
                      alt="Icon"
                    />
                  </div>
                </div>
                <Link to="/Air">
                  {" "}
                  <h5 className="text-white">Air Freight</h5>
                </Link>
                <hr className="w-25" />
                <span>
                In the realm of time-sensitive supply chains, {companyname}{" "}
                emerges as your ultimate solution with our expansive worldwide
                air freight services.
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img className="img-fluid" src="img/ship.png" alt="Icon" />
                  </div>
                </div>
                <Link to="/Ocean">
                  <h5 className="text-white">Ocean Freight</h5>
                </Link>
                <hr className="w-25" />
                <span>
                ocean freight stands as an indispensable cornerstone of global
                commerce, serving as the robust backbone of the world economy.
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src="img/freight.png"
                      alt="Icon"
                    />
                  </div>
                </div>
                <Link to="/Ground">
                  {" "}
                  <h5 className="text-white">Ground Freight</h5>
                </Link>
                <hr className="w-25" />
                <span>
                At {companyname}, Ground
                freight serves as a conduit that harmonizes precision with
                progress, bridging geographical gaps with unwavering efficacy.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Facts Start */}
      {/* About Start */}
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/about 1.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">About Us</h1>
                <p className="mb-4 pb-2">
                As a distinguished Freight and Trading services provider nestled in the vibrant city of Mumbai, Maharashtra, our aspirations reach far beyond conventional horizons. Our ambition stands as a beacon guiding us towards a future where health, safety, and environmental well-being resonate at the core of our organizational ethos. The tapestry of our endeavors is intricately woven with a commitment to cultivate and uphold a world-class infrastructure that forms the bedrock of employee contentment – a cornerstone that reverberates through the corridors of customer loyalty, propelling us towards sustained profit growth and the creation of enriched company value.
<br/>
Rooted in inspiration, we ardently navigate the labyrinth of development, steadfastly nurturing an ecosystem that nurtures the well-being of our workforce and the prosperity of our clientele. With each endeavor, we embark on a journey to transcend the boundaries of expectation, setting the stage for the orchestration of total quality management that resonates across every facet of our operations.
                
                <br/><br/>
                Our compass points resolutely towards a single objective – the delivery of cost-effective and reliable solutions that serve as catalysts for the realization of our customers' loftiest aspirations. The fulcrum of our existence revolves around the notion of exceeding expectations, transforming every challenge into an opportunity, and embarking on a relentless pursuit of excellence.
                </p>
               
                
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
