import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <div className="container-fluid p-0 pb-5">
        <div className=" header-carousel position-relative">
          <div className="owl-carousel-item position-relative w3-banner jarallax">
            {" "}
            <video className="img-fluid" autoPlay muted loop style={{}}>
              <source src="img/video.mp4" type="video/mp4" />
            </video>
            <img className="img-fluid" src="img/carousel-1.jpg" alt="" />
            <div className="carousel-inner">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-8 text-center">
                    <h1 className="display-3 text-white animated slideInDown mb-4">
                      Best Freight Forwarding And Trading Solutions Company..
                    </h1>
                    <p className="fs-5 text-white mb-4 pb-2">
                    we are architects of streamlined efficiency, navigators of intricate global trade routes, and partners in your journey towards triumph. With an unwavering commitment to excellence, we have etched our name as the epitome of the best in the business.
                    </p>
                    <Link
                      to ="/About"
                      className="btn btn-primary rounded-pill py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-light rounded-pill py-md-3 px-md-5 animated slideInRight"
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Carousel End */}
      {/* Facts Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src="img/air-plane.png"
                      alt="Icon"
                    />
                  </div>
                </div>
                <Link to="/Air">
                  {" "}
                  <h5 className="text-white">Air Freight</h5>
                </Link>
                <hr className="w-25" />
                <span>
                In the realm of time-sensitive supply chains, {companyname}{" "}
                emerges as your ultimate solution with our expansive worldwide
                air freight services.
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img className="img-fluid" src="img/ship.png" alt="Icon" />
                  </div>
                </div>
                <Link to="/Ocean">
                  <h5 className="text-white">Ocean Freight</h5>
                </Link>
                <hr className="w-25" />
                <span>
                ocean freight stands as an indispensable cornerstone of global
                commerce, serving as the robust backbone of the world economy.
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src="img/freight.png"
                      alt="Icon"
                    />
                  </div>
                </div>
                <Link to="/Ground">
                  {" "}
                  <h5 className="text-white">Ground Freight</h5>
                </Link>
                <hr className="w-25" />
                <span>
                At {companyname}, Ground
                freight serves as a conduit that harmonizes precision with
                progress, bridging geographical gaps with unwavering efficacy.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Facts Start */}
      {/* About Start */}
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/about 1.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">About Us</h1>
                <p className="mb-4 pb-2">
                As a distinguished Freight and Trading services provider nestled in the vibrant city of Mumbai, Maharashtra, our aspirations reach far beyond conventional horizons. Our ambition stands as a beacon guiding us towards a future where health, safety, and environmental well-being resonate at the core of our organizational ethos. The tapestry of our endeavors is intricately woven with a commitment to cultivate and uphold a world-class infrastructure that forms the bedrock of employee contentment – a cornerstone that reverberates through the corridors of customer loyalty, propelling us towards sustained profit growth and the creation of enriched company value.
<br/>
Rooted in inspiration, we ardently navigate the labyrinth of development, steadfastly nurturing an ecosystem that nurtures the well-being of our workforce and the prosperity of our clientele. With each endeavor, we embark on a journey to transcend the boundaries of expectation, setting the stage for the orchestration of total quality management that resonates across every facet of our operations.
                </p>
               
                <Link to="/About" className="btn btn-primary rounded-pill py-3 px-5">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      {/* Projects Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-primary mb-3 mx-auto"
              style={{ width: "60px", height: "2px" }}
            />
            <h1 className="display-5 mb-5">Our Freight Forwarding Services</h1>
          </div>

          <div className="row g-4 portfolio-container">
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/air.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Air Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Air Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Air"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/ocean.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Ocean Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Ocean Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Ocean"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/road.webp"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Ground Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Ground Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Ground"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/rail.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Rail Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Rail Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Rail"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/project.png"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Projects Logistics</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Projects Logistics</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Projects"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  style={{ height: "240px" }}
                  src="img/customs.png"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-0">Customs Brokerage</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Freight Service</p>
                  <h5 className="lh-base mb-3">Customs Brokerage</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/Customs"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Projects End */}
      {/* Quote Start */}
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container quote px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/about 2.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 quote-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-5">Contact Us</h1>
                <p className="mb-4 pb-2">
                  Please Fill the Form to reach us...
                </p>
                <form
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          className
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <textarea
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        name="submit"
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                <h1>{result}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Quote End */}
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-primary mb-3 mx-auto"
              style={{ width: "60px", height: "2px" }}
            />
            <h1 className="display-5 mb-5">Trading Solutions We Provide..</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/fashion.webp"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Fashion & Apparel</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/food.webp"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Food & Beverage</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/oil.jpg"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Oil, Gas & Energy</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/fmcg.jpg"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">FMCG</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/electronics.jpg"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Electronics</h5>
                  <span className="text-primary">Trading Services</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/agriculture.webp"
                    alt=""
                    style={{ height: "280px" }}
                  />
                  <div className="team-social">
                    <Link
                      to="/Trading"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Fashion & Apparel</h5>
                  <span className="text-primary">
                    Agriculture & Agribusiness
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
