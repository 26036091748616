import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>


<div className="container-fluid bg-dark text-secondary footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6">
                <h5 className="text-light mb-4">{companyname}</h5>
                <Link to="/Home" className="navbar-brand d-flex align-items-center">
           <img src="img/logo.png" style={{height:"80px"}}></img>
          </Link>
                <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />{companyaddress}</p>
                <p className="mb-2"><i className="fa fa-phone-alt me-3" />{companynumber}</p>
                <p className="mb-2"><i className="fa fa-envelope me-3" />{companyemail}</p>
               
              </div>
              <div className="col-lg-3 col-md-6">
                <h5 className="text-light mb-4">Quick Links</h5>
                <Link to="/Home" className="btn btn-link">Home</Link>
                <Link to="/About" className="btn btn-link">About</Link>
                <Link to="/Services" className="btn btn-link">Freight Services</Link>
                <Link to="/Trading" className="btn btn-link">Trading Services</Link>
                <Link to="/Contact" className="btn btn-link">Contact</Link>
                <Link to="/Getquote" className="btn btn-link">Getquote</Link>
                <Link to="/Privacy" className="btn btn-link">Privacy policy</Link>
                <Link to="/Terms" className="btn btn-link">Terms</Link>


              </div>
              <div className="col-lg-3 col-md-6">
                <h5 className="text-light mb-4">Services</h5>
                <Link to="/Air" className="btn btn-link">Air Freight</Link>
                  <Link to="/Ocean" className="btn btn-link">Ocean Freight</Link>
                  <Link to="/Ground" className="btn btn-link">Ground Freight</Link>
                  <Link to="/Rail" className="btn btn-link">Rail Freight</Link>
                  <Link to="/Projects" className="btn btn-link">Projects Logistics</Link>
                  <Link to="/Customs" className="btn btn-link"> Customs Brokerage</Link>
              </div>
              
              <div className="col-lg-3 col-md-6">
                <h5 className="text-light mb-4">Services</h5>
                <Link to="/Trading" className="btn btn-link">Fashion & Apparel</Link>
                  <Link to="/Trading" className="btn btn-link">Food & Beverage</Link>
                  <Link to="/Trading" className="btn btn-link">Oil, Gas & Energy</Link>
                  <Link to="/Trading" className="btn btn-link">FMCG</Link>
                  <Link to="/Trading" className="btn btn-link">Electronics </Link>
                  <Link to="/Trading" className="btn btn-link">Agriculture & Agribusiness</Link>
              </div>
            </div>
          </div>
        </div>
        {/* Footer End */}
        {/* Copyright Start */}
        <div className="container-fluid py-4" style={{background: '#000000'}}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©2023 <Link className="border-bottom" to="/Home">{companyname}</Link>, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">

              </div>
            </div>
          </div>
        </div>
        {/* Copyright End */}
        {/* Back to Top */}
        <Link to="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up" /></Link>

    </>
  );
};

export default Footer;
